import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Switch } from "react-router-dom";

import Routes from './routes';
import Nav from './components/nav.component';


function App() {
  return (
    <Router>
      <div className="App">
        <Nav />
        <div className="outer">
          <div className="inner">
            <Switch>
              <Routes />
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
