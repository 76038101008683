import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { UserProvider } from "./contexts/User/index"
import tokenRefresh from "./util/tokenRefresh";


tokenRefresh();

ReactDOM.render(
  <BrowserRouter>
        <UserProvider>
          <App />
        </UserProvider>
    </BrowserRouter>,
    document.getElementById("root")
);

serviceWorker.unregister();
