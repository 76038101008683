import React from 'react';
import TokenService from "../services/tokenService";
import { useHistory } from 'react-router-dom';


export default (props) => {
  const history = useHistory();
  TokenService.removeUser();
  window.localStorage.clear();
  history.push('/');

  return (
    <p>Logging Out...</p>
  );
}
