import React from "react";
import { getApi, deleteApi } from '../api/api';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

export default (props) => {

  const history = useHistory();

  const [triggerList, setTriggerList] = useState([]);

  const truncate = function (fullStr, strLen, separator) {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || '...';

    var sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow/2),
        backChars = Math.floor(charsToShow/2);

    return fullStr.substr(0, frontChars) +
           separator +
           fullStr.substr(fullStr.length - backChars);
  };

  const updateList = () => {
    getApi('/triggers').then((t) => {
      setTriggerList(t);
    }).catch((e) => console.log("Could not get triggers with error: ", e));
  }

  if(triggerList.length === 0) {
    updateList();
  }

  const createTrigger = () => {
    history.push('/trigger');
  }

  const updateTrigger = (triggerId) => {
    history.push(`/trigger/${triggerId}`);
  }

  const deleteTrigger = async (triggerId) => {
    await deleteApi(`/trigger/${triggerId}`).then((t) => {
      console.log("Trigger was deleted: ", t);
    }).catch((e) => {
      console.log("Could not delete Trigger: ", e);
    });
    updateList();
  }

  return (
    <div class="listViewContain">
      <h2>Notification Triggers &nbsp;
        <button type="button" className="btn btn-outline-success" onClick={createTrigger}>+</button>
      </h2>
      <div className="SimpleList">
        <ul>
          {triggerList.map(function(trigger){
            return (
              <li key={ trigger._id }>
                <img alt="ETH Logo" src="/ETH.svg" />
                <h3>{trigger.name}</h3>
                <div>{trigger.addresses.map((t) => truncate(t, 24) ).join(', ')}</div>
                <div className="btn-group mr-2 SimpleGroup" role="group" aria-label="CRUD">
                  <button type="button" className="btn btn-outline-success btn-sm" onClick={ () => updateTrigger(trigger._id) }>✎</button> 
                  <button type="button" className="btn btn-outline-danger btn-sm" onClick={ () => deleteTrigger(trigger._id) }>🗑</button>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
