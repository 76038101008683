import React from 'react';
//import { UserContext } from "../contexts/User/index";
import { Route, Redirect } from 'react-router-dom';
import TokenService from "../services/tokenService";

export default function AuthRoute({
    comp: Component, // use comp prop
    ...rest
  }) {
   return (
     <Route
      {...rest}
      render={props =>
        !TokenService.getLocalAccessToken() ? (
          <Redirect to="/Sign-in" />
        ) : (
          <Component {...props} />
        )
      }
    />
   )
}
