import TokenService from "../services/tokenService";
const env = require('../env.json');


const makeApiCall = async (verb, path, body, isRefresh) => {
  const requestPath = env.API_URL + path;
  const authToken = (!isRefresh)? TokenService.getLocalAccessToken(): TokenService.getLocalRefreshToken();

  const headers = { 'Content-Type': 'application/json' }

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  const requestOptions = {
      method: verb,
      headers: headers,
  };

  if(body) requestOptions.body = JSON.stringify(body);

  console.log(requestPath, verb, path, isRefresh);

  return fetch(requestPath, requestOptions)
    .then(response => {
      if(!response.ok) throw new Error(response.status);
      else return response.json();
    })
    .catch(async (e) => {
      if(e.message === '401' && !isRefresh) {
        return refreshTokenApi().then((r) => {
          if(!r.ok) throw new Error(r.status);
          const newCreds = r.json();
          TokenService.updateLocalRefreshToken(newCreds.refreshToken);
          TokenService.updateLocalAccessToken(newCreds.token);
          return makeApiCall(verb, path, body);
        });
      } else {
        throw e;
      }
    });
}

export const getApi = async   (path) => {
  return makeApiCall('GET', path);
};

export const postApi = async (path, body) => {
  return makeApiCall('POST', path, body);
};

export const putApi = async (path, body) => {
  return makeApiCall('PUT', path, body);
};

export const deleteApi = async (path, body) => {
  return makeApiCall('DELETE', path, body);
};

export const refreshTokenApi = async () => {
  return makeApiCall('GET', '/auth/token/refresh', undefined, true);
}
