import React from "react";
//import { UserContext } from "../contexts/User/index";
import { useHistory } from 'react-router-dom';
import { postApi, getApi } from '../api/api';
import { useState } from 'react';

export default () => {

  const history = useHistory();

  //const [ state ] = React.useContext(UserContext);

  const [isUsernameProvided, setIsUsernameProvided] = useState(true);
  const [isPasswordProvided, setIsPasswordProvided] = useState(true);
  const [isConfirmPasswordProvided, setIsConfirmPasswordProvided] = useState(true);
  const [isEmailProvided, setIsEmailProvided] = useState(true);
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [isUniqueUsername, setIsUniqueUsername] = useState(true);
  const [isSubsAvailable, setIsSubsAvailable] = useState(false);
  const [subsData, setSubsData] = useState([]);
  const [subSelected, setSubSelected] = useState("");
  const [isSubProvided, setIsSubProvided] = useState(true)

  if(!isSubsAvailable) {
    getApi('/subscriptions/available').then((s) => {
      setSubsData(s);
      setIsSubsAvailable(true);
    }).catch((e) => {
      console.log("Could not get available subscriptions: ", e);
      setIsSubsAvailable(false);
    });
  }

  const subWasSelected = (event) => {
    setSubSelected(event.target.value);
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const validateForm = (form) => {
    (!form.username.value)? setIsUsernameProvided(false): setIsUsernameProvided(true);
    (!form.email.value)? setIsEmailProvided(false): setIsEmailProvided(true);
    (!form.password.value)? setIsPasswordProvided(false): setIsPasswordProvided(true);
    (!form.confirmPassword.value)? setIsConfirmPasswordProvided(false): setIsConfirmPasswordProvided(true);
    (form.password.value !== form.confirmPassword.value)? setIsPasswordMatch(false): setIsPasswordMatch(true);
    (subSelected === "")? setIsSubProvided(false): setIsSubProvided(true);

    return (
      isUsernameProvided &&
      isPasswordProvided &&
      isEmailProvided &&
      isPasswordMatch &&
      isSubProvided
    );
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;

    if(validateForm(form)) {

      const fd = {
        username: form.username.value,
        email: form.email.value,
        password: form.password.value,
        subId: subSelected
      };

      await postApi('/auth/register', fd).then((r) => {
        history.push('/sign-in');
      }).catch((e) => {
        if(e.message === '409') setIsUniqueUsername(false);
      })
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3>Register</h3>

      <div className="form-group">
        <label>username</label>
        <input name="username" type="text" className="form-control" placeholder="Enter username" />
        <div className="form-validation-error">{ isUsernameProvided? "": "Please provide a username" }</div>
        <div className="form-validation-error">{ isUniqueUsername? "": "Username already exists" }</div>
      </div>

      <div className="form-group">
        <label>Email</label>
        <input name="email" type="email" className="form-control" placeholder="Enter email" />
        <div className="form-validation-error">{ isEmailProvided? "": "Please provide an email" }</div>
      </div>

      <div className="form-group">
        <label>Password</label>
        <input name="password" type="password" className="form-control" placeholder="Enter password" />
        <div className="form-validation-error">{ isPasswordProvided? "": "Please provide a password" }</div>
      </div>

      <div className="form-group">
        <label>Confirm  Password</label>
        <input name="confirmPassword" type="password" className="form-control" placeholder="Re-Enter password" />
        <div className="form-validation-error">{ isConfirmPasswordProvided? "": "Please re-enter password" }</div>
        <div className="form-validation-error">{ isPasswordMatch? "": "Password does not match" }</div>
      </div>


      { isSubsAvailable && (
        <div className="form-group">
        <label>Choose a subscription</label>
        { subsData.map((sub) =>
          <div><label><input type="radio" value={sub._id} name="subId" onChange={subWasSelected}/> {sub.isPriceTBD? "$TBD": formatter.format(sub.price)} {sub.name}</label></div>
        )}
        <div className="form-validation-error">{ isSubProvided? "": "Please select a subscription" }</div>
        </div>
      )}

      <button type="submit" className="btn btn-dark btn-lg btn-block">Register</button>

      <p className="forgot-password text-right">
        Already registered <a href="/#">log in?</a>
      </p>
    </form>
  );
}
