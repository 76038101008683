import TokenService from "../services/tokenService";
import { refreshTokenApi } from '../api/api';

export default () => {
  const doRefresh = async () => {
    const rt = TokenService.getLocalRefreshToken();
    if(rt) {
      await refreshTokenApi().then((newCreds) => {
        TokenService.updateLocalRefreshToken(newCreds.refreshToken);
        TokenService.updateLocalAccessToken(newCreds.token);
      }).catch((e) => {
        console.log("Could not refresh tokens: ", e);
      });
    }
    return setTimeout(doRefresh, 60000);
  };
  doRefresh();
}
