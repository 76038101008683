export const reducer = (state, action) => {
  //console.log("I was called: ", state, action);

  const key = Object.getOwnPropertyNames(action)[0];

  switch (key) {
    case "authToken":
      return {
        ...state,
        authToken: action.authToken
      }
    case "refreshToken":
      localStorage.setItem('refreshToken', action.refreshToken);
      return {
        ...state,
        refreshToken: action.refreshToken
      }
    default:
      state[key] = action[key];
      return state;
  }
}

export const initialState = {
  authToken: undefined,
  refreshToken: localStorage.getItem('refreshToken')
}
