import React from "react";
import { getApi, deleteApi } from '../api/api';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

export default (props) => {

  const history = useHistory();

  const [serviceKeyList, setServiceKeyList] = useState([]);

  const updateList = () => {
    getApi('/serviceKeys').then((t) => {
      setServiceKeyList(t);
    }).catch((e) => console.log("Could not get serviceKeys with error: ", e));
  }

  if(serviceKeyList.length === 0) {
    updateList();
  }

  const createServiceKey = () => {
    history.push('/serviceKey');
  }

  const updateServiceKey = (serviceKeyId) => {
    history.push(`/serviceKey/${serviceKeyId}`);
  }

  const deleteServiceKey = async (serviceKeyId) => {
    await deleteApi(`/serviceKey/${serviceKeyId}`).then((t) => {
      console.log("ServiceKey was deleted: ", t);
    }).catch((e) => {
      console.log("Could not delete ServiceKey: ", e);
    });
    updateList();
  }

  return (
    <div class="listViewContain">
      <h2>Service Keys &nbsp;
        <button type="button" className="btn btn-outline-success" onClick={createServiceKey}>+</button>
      </h2>
      <div className="SimpleList">
        <ul>
          {serviceKeyList.map(function(serviceKey){
            return (
              <li key={ serviceKey._id }>
                <img alt="Etherscan Logo" src="/etherscan.svg" />
                <h3>{serviceKey.account}</h3>
                <div>{serviceKey.apiKey}</div>
                <div className="btn-group mr-2 SimpleGroup" role="group" aria-label="CRUD">
                  <button type="button" className="btn btn-outline-success btn-sm" onClick={ () => updateServiceKey(serviceKey._id) }>✎</button> 
                  <button type="button" className="btn btn-outline-danger btn-sm" onClick={ () => deleteServiceKey(serviceKey._id) }>🗑</button>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
