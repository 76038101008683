import React from "react";
import { useHistory, Link } from 'react-router-dom';
import { postApi, getApi, putApi } from '../api/api';
import { useState } from 'react';
import Web3Utils from 'web3-utils';

export default (props) => {
  console.log(props);
  const history = useHistory();

  const [isUpdate, triggerId] = (() => {
    const p = props.location.pathname.split("/");
    const isUp = (p.length > 2)? true: false;
    const tId = (isUp)? p.pop(): null;
    return [isUp, tId];
  })();

  const [ isDataFetched, setIsDataFetched ] = useState(false);
  const [ triggerData, setTriggerData ] = useState({ addresses: [] });
  const [ isNameProvided, setIsNameProvided ] = useState(true);
  const [ isAddressesProvided, setIsAddressesProvided ] = useState(true);
  const [ isOutputLocationIdProvided, setIsOutputLocationIdProvided ] = useState(true);
  const [ isProperlyFormattedAddresses, setIsProperlyFormattedAddresses ] = useState(true);
  const [ isOnlyOneAddress, setIsOnlyOneAddress ] = useState(true);

  if(isUpdate && !isDataFetched) {
    getApi(`/trigger/${triggerId}`).then((t) => {
      setIsDataFetched(true);
      setTriggerData(t);
    }).catch((e) => {
      console.log("Could not fetch trigger data: ", e);
      history.push("/triggers");
    });
  }

  const validateForm = (form) => {
    (!form.name.value)? setIsNameProvided(false): setIsNameProvided(true);
    (!form.addresses.value)? setIsAddressesProvided(false): setIsAddressesProvided(true);
    (!form.outputLocationId.value)? setIsOutputLocationIdProvided(false): setIsOutputLocationIdProvided(true);
    (form.addresses.value.indexOf(",") > -1)? setIsOnlyOneAddress(false): setIsOnlyOneAddress(true);

    if(form.addresses.value) {
      const addresses = formatAddresses(form.addresses.value);
      (!addresses.reduce((acc, item) => (!acc)? false: Web3Utils.isAddress(item), true))?
        setIsProperlyFormattedAddresses(false):
        setIsProperlyFormattedAddresses(true);
    }

    return isNameProvided &&
      isAddressesProvided &&
      isOutputLocationIdProvided &&
      isProperlyFormattedAddresses &&
      !(form.addresses.value.indexOf(",") > -1)
  }

  const formatAddresses = (adrs) => {
    let sa = adrs.split(",");
    if(sa.length < 2) {
      sa = adrs.split(" ");
    }
    return sa.map((a) => a.trim());
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    if(validateForm(form)) {
      const fd = {
        name: form.name.value,
        addresses: formatAddresses(form.addresses.value),
        outputLocationId: form.outputLocationId.value
      };

      if(isUpdate) {
        await putApi(`/trigger/${triggerId}`, fd).then((r) => {
          history.push('/triggers');
        }).catch((e) => {
          console.log("PUT /trigger: Error: ", e);
        })
      } else {
        await postApi('/trigger', fd).then((r) => {
          history.push('/triggers');
        }).catch((e) => {
          console.log("POST /trigger: Error: ", e);
        });
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <h3>Create Trigger</h3>

      <div className="form-group">
        <label>Name</label>
        <input name="name" type="text" className="form-control" defaultValue={triggerData.name} placeholder="Enter Trigger Name" />
        <div className="form-validation-error">{ isNameProvided? "": "Please provide a Trigger Name" }</div>
      </div>

      <div className="form-group">
        <label>Wallet Address</label>
        <input type="text" name="addresses" className="form-control" placeholder="Enter Ethereum Addresses" defaultValue={ triggerData.addresses.join(", ") } />
        <div className="form-validation-error">{ isAddressesProvided? "": "Please Enter Ethereum Wallet Address" }</div>
        <div className="form-validation-error">{ isProperlyFormattedAddresses? "": "Address is not valid" }</div>
        <div className="form-validation-error">{ isOnlyOneAddress? "": "You may only enter one address" }</div>
      </div>

      <div className="form-group">
        <label>Tellagram Chat ID*</label>
        <input name="outputLocationId" type="text" className="form-control" defaultValue={ triggerData.outputLocationId } placeholder="Enter Tellagram Chat ID" />
        <div className="form-validation-error">{ isOutputLocationIdProvided? "": "Please provide a Tellagram Chat ID" }</div>
        <div className="form-validation-error">
          <br/> &nbsp; * Create a new group and add @JPEGGER_NOTIFY_BOT.
          <br/> &nbsp; Then type /chat_id and you will be messaged the Chat ID.
          <br/>
        </div>
      </div>

      <button type="submit" className="btn btn-dark btn-lg btn-block">{ isDataFetched? 'Update': 'Create' }</button>

      <p className="forgot-password text-right">
        <Link to={"/triggers"}>&lt; Back</Link>
      </p>
    </form>
  );
}
