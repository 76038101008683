import React from "react";
import { useState } from 'react';
import { postApi } from '../api/api';
import { useHistory } from 'react-router-dom';
import TokenService from "../services/tokenService";

export default (props) => {

  const history = useHistory();

  const [isUsernameProvided, setIsUsernameProvided] = useState(true);
  const [isPasswordProvided, setIsPasswordProvided] = useState(true);

  const validateForm = (form) => {
    (!form.username.value)? setIsUsernameProvided(false): setIsUsernameProvided(true);
    (!form.password.value)? setIsPasswordProvided(false): setIsPasswordProvided(true);
    return (form.username.value && form.password.value);
  }

  if(TokenService.getUser()) {
    history.push('/triggers');
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;

    if(validateForm(form)) {
      const fd = { username:  form.username.value, password: form.password.value };
      await postApi('/auth/login', fd).then((r) => {
        TokenService.setUser(r);
        history.push('/triggers');
      }).catch((e) => {
        console.log("/auth/login: Error: ", e);
      });
    }
  }

  return (
      <form onSubmit={handleSubmit}>
          <h3>Log in</h3>

          <div className="form-group">
              <label>Username</label>
              <input name="username" type="text" className="form-control" placeholder="Enter username" />
              <div className="form-validation-error">{ isUsernameProvided? "": "Please provide a username" }</div>
          </div>

          <div className="form-group">
              <label>Password</label>
              <input name="password" type="password" className="form-control" placeholder="Enter password" />
              <div className="form-validation-error">{ isPasswordProvided? "": "Please provide a password" }</div>
          </div>

          <div className="form-group">
              <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="customCheck1" />
                  <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
              </div>
          </div>

          <button type="submit" className="btn btn-dark btn-lg btn-block">Sign in</button>
      </form>
  );
};
