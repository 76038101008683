import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from "./components/login.component";
import SignUp from "./components/signup.component";
import Triggers from "./components/triggers.component";
import Trigger from "./components/trigger.component";
import ServiceKeys from "./components/serviceKeys.component";
import ServiceKey from "./components/serviceKey.component";
import AuthRoute from "./auth/authRouter.component";
import Logout from "./components/logout.component";
import Subscription from "./components/subscription.component";
import Subscriptions from "./components/subscriptions.component";
import Account from "./components/account.component";

export default (props) => {
  return (
    <Route path="/">
      <Switch>
        <Route exact path='/' component={Login} />
        <Route path="/sign-in" component={Login} />
        <Route path="/sign-up" component={SignUp} />
        <Route path="/Logout" component={Logout} />
        <AuthRoute path="/account" component={Account} />
        <AuthRoute path="/account/:userId" component={Account} />
        <AuthRoute path="/triggers" comp={Triggers} />
        <AuthRoute path="/trigger" comp={Trigger} />
        <AuthRoute path={"/trigger/:id"} comp={Trigger} />
        <AuthRoute path="/subscription" comp={Subscription} />
        <AuthRoute path="/subscription/:id" comp={Subscription} />
        <AuthRoute path="/subscriptions" comp={Subscriptions} />
        <AuthRoute path="/serviceKeys" comp={ServiceKeys} />
        <AuthRoute path="/serviceKey" comp={ServiceKey} />
        <AuthRoute path="/serviceKey/:id" comp={ServiceKey} />
      </Switch>
    </Route>
  )
}
