import React from 'react';

import { Link } from "react-router-dom";
import TokenService from "../services/tokenService";
import { useState } from 'react';

export default (props) => {

  const [userRole, setUserRole] = useState(TokenService.getUserRole());

  //if(userRole !== TokenService.getUserRole()) setUserRole(TokenService.getUserRole());

  setInterval(() => {
    if(TokenService.getUserRole() !== userRole) {
      setUserRole(TokenService.getUserRole());
    }
  }, 100);

  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top">
      <div className="container">
        <Link className="navbar-brand" to={"/sign-in"}>JPEGGERS</Link>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav ml-auto">
            <li className={(userRole)? 'nav-item': 'hidden'}>
              <Link className="nav-link" to={"/triggers"}>Triggers</Link>
            </li>
            <li className={(userRole)? 'nav-item': 'hidden'}>
              <Link className="nav-link" to={"/account"}>Account</Link>
            </li>
            <li className={(userRole === 'ADMIN')? 'nav-item': 'hidden'}>
              <Link className="nav-link" to={"/subscriptions"}>Subscription Admin</Link>
            </li>
            <li className={(userRole === 'ADMIN')? 'nav-item': 'hidden'}>
              <Link className="nav-link" to={"/serviceKeys"}>Service Keys</Link>
            </li>
            <li className={(!userRole)? 'nav-item': 'hidden'}>
              <Link className="nav-link" to={"/sign-in"}>Sign in</Link>
            </li>
            <li className={(!userRole)? 'nav-item': 'hidden'}>
              <Link className="nav-link" to={"/sign-up"}>Sign up</Link>
            </li>
            <li className={(userRole)? 'nav-item': 'hidden'}>
              <Link className="nav-link" to={"/logout"}>Logout</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
