import React from "react";
import { useHistory, Link } from 'react-router-dom';
import { postApi, getApi, putApi } from '../api/api';
import { useState } from 'react';

export default (props) => {
  console.log(props);
  const history = useHistory();

  const [isUpdate, subscriptionId] = (() => {
    const p = props.location.pathname.split("/");
    const isUp = (p.length > 2)? true: false;
    const tId = (isUp)? p.pop(): null;
    return [isUp, tId];
  })();

  const [ isDataFetched, setIsDataFetched ] = useState(false);
  const [ subscriptionData, setSubscriptionData ] = useState({});
  const [ isNameProvided, setIsNameProvided ] = useState(true);
  const [ isDescProvided, setIsDescProvided ] = useState(true);
  const [ isLengthInDaysProvided, setIsLengthInDaysProvided ] = useState(true);
  const [ isPriceProvided, setIsPriceProvided ] = useState(true);
  const [ isTotalTriggersProvided, setIsTotalTriggersProvided ] = useState(true);

  if(isUpdate && !isDataFetched) {
    getApi(`/subscription/${subscriptionId}`).then((t) => {
      t.lengthInDays = t.lengthInDays? t.lengthInDays: 4;
      setIsDataFetched(true);
      setSubscriptionData(t);
    }).catch((e) => {
      console.log("Could not fetch subscription data: ", e);
      history.push("/subscriptions");
    });
  }

  const validateForm = (form) => {
    (!form.name.value)? setIsNameProvided(false): setIsNameProvided(true);
    (!form.desc.value)? setIsDescProvided(false): setIsDescProvided(true);
    (isNaN(form.lengthInDays.value))? setIsLengthInDaysProvided(false): setIsLengthInDaysProvided(true);
    (isNaN(form.price.value))? setIsPriceProvided(false): setIsPriceProvided(true);
    (isNaN(form.totalTriggers.value))? setIsTotalTriggersProvided(false): setIsTotalTriggersProvided(true);

    return form.name.value
      && form.desc.value
      && !isNaN(form.lengthInDays.value)
      && !isNaN(form.price.value)
      && !isNaN(form.totalTriggers.value)
  }

  const handleIsActiveClick = (event) => {
    setSubscriptionData({ ...subscriptionData, isActive: event.target.checked })
  }

  const handleIsVisibleClick = (event) => {
    setSubscriptionData({ ...subscriptionData, isVisible: event.target.checked })
  }

  const handleIsPriceTBDClick = (event) => {
    setSubscriptionData({ ...subscriptionData, isPriceTBD: event.target.checked })
  }

 


  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    if(validateForm(form)) {
      const fd = {
        name: form.name.value,
        desc: form.desc.value,
        lengthInDays: parseInt(form.lengthInDays.value),
        totalTriggers: parseInt(form.totalTriggers.value),
        price: parseFloat(form.price.value),
        isActive: form.isActive.checked,
        isVisible: form.isVisible.checked,
        isPriceTBD: form.isPriceTBD.checked
      };

      console.log(fd);

      if(isUpdate) {
        await putApi(`/subscription/${subscriptionId}`, fd).then((r) => {
          history.push('/subscriptions');
        }).catch((e) => {
          console.log("PUT /subscription: Error: ", e);
        })
      } else {
        await postApi('/subscription', fd).then((r) => {
          history.push('/subscriptions');
        }).catch((e) => {
          console.log("POST /subscription: Error: ", e);
        });
      }
    }
  }

  return (
    <form onSubmit={handleSubmit} class="listViewContainLarge">
      <h3>Create A Service Key</h3>

      <div className="form-group">
        <label>Name</label>
        <input name="name" type="text" className="form-control" defaultValue={subscriptionData.name} placeholder="Enter Subscription Name" />
        <div className="form-validation-error">{ isNameProvided? "": "Please provide a Subscription Name" }</div>
      </div>

      <div className="form-group">
        <label>Description</label>
        <input name="desc" type="text" className="form-control" defaultValue={subscriptionData.desc} placeholder="Enter A Description" />
        <div className="form-validation-error">{ isDescProvided? "": "Please provide an Description" }</div>
      </div>

      <div className="form-group">
        <label>Length In Days</label>
        <input name="lengthInDays" type="text" className="form-control" defaultValue={subscriptionData.lengthInDays} placeholder="Enter the subscription length in days (Number)" />
        <div className="form-validation-error">{ isLengthInDaysProvided? "": "Please provide number of days" }</div>
      </div>

      <div className="form-group">
        <label>Total Number Of Triggers</label>
        <input name="totalTriggers" type="text" className="form-control" defaultValue={subscriptionData.totalTriggers} placeholder="Enter the number of allowed triggers" />
        <div className="form-validation-error">{ isTotalTriggersProvided? "": "Please provide the number of allowed triggers" }</div>
      </div>

      <div className="form-group">
        <label>Price</label>
        <input name="price" type="text" className="form-control" defaultValue={subscriptionData.price} placeholder="Enter the subscription price in $'s'" />
        <div className="form-validation-error">{ isPriceProvided? "": "Please provide the subscription price in $'s'" }</div>
      </div>

      <div className="form-group">
        <label>Is Active</label>
        <input name="isActive" type="checkbox" className="form-control" checked={subscriptionData.isActive} value="true" onClick={handleIsActiveClick} />
        <div className="form-validation-error"></div>
      </div>

      <div className="form-group">
        <label>Is Visible</label>
        <input name="isVisible" type="checkbox" className="form-control" checked={subscriptionData.isVisible} value="true" onClick={handleIsVisibleClick} />
        <div className="form-validation-error"></div>
      </div>

      <div className="form-group">
        <label>Is Price TBD</label>
        <input name="isPriceTBD" type="checkbox" className="form-control" checked={subscriptionData.isPriceTBD} value="true" onClick={handleIsPriceTBDClick} />
        <div className="form-validation-error"></div>
      </div>


      <button type="submit" className="btn btn-dark btn-lg btn-block">{ isDataFetched? 'Update': 'Create' }</button>

      <p className="forgot-password text-right">
        <Link to={"/subscriptions"}>&lt; Back</Link>
      </p>
    </form>
  );
}
