import React from "react";
//import { UserContext } from "../contexts/User/index";
import { useHistory } from 'react-router-dom';
import { putApi, getApi } from '../api/api';
import { useState } from 'react';
import TokenService from "../services/tokenService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default (props) => {

  const history = useHistory();

  //const [ state ] = React.useContext(UserContext);

  const [isUsernameProvided, setIsUsernameProvided] = useState(true);
  const [isPasswordProvided, setIsPasswordProvided] = useState(true);
  const [isConfirmPasswordProvided, setIsConfirmPasswordProvided] = useState(true);
  const [isEmailProvided, setIsEmailProvided] = useState(true);
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [isUniqueUsername, setIsUniqueUsername] = useState(true);
  const [isSubsAvailable, setIsSubsAvailable] = useState(false);
  const [subsData, setSubsData] = useState([]);
  const [subSelected, setSubSelected] = useState("");
  const [isSubProvided, setIsSubProvided] = useState(true);
  const [isUserDataAvailable, setIsUserDataAvailable] = useState(false);
  const [userData, setUserData] = useState({});

  const userId = (() => {
    const p = props.location.pathname.split("/");
    const isUp = (p.length > 2)? true: false;
    const uId = (isUp)? p.pop(): TokenService.getUser()._id;
    return uId;
  })();

  const getSubIdFromUser = (u) => {
    try {
      return u.subscription.subId;
    } catch(e) {
      return "";
    }
  }

  if(!isUserDataAvailable) {
    getApi(`/user/${userId}`).then((u) => {
      setUserData(u);
      setSubSelected(getSubIdFromUser(u));
      setIsUserDataAvailable(true)
    }).catch((e) => {
      console.log("Could not get user data with error: ", e);
      setIsUserDataAvailable(false);
    })
  }


  if(!isSubsAvailable) {
    getApi('/subscriptions/available').then((s) => {
      setSubsData(s);
      setIsSubsAvailable(true);
    }).catch((e) => {
      console.log("Could not get available subscriptions: ", e);
      setIsSubsAvailable(false);
    });
  }

  const subWasSelected = (event) => {
    setSubSelected(event.target.value);
  }

  const iAmTheSub = (subId) => {
    return () => {
      return (subId === subSelected);
    }
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const validateForm = (form) => {
    (!form.username.value)? setIsUsernameProvided(false): setIsUsernameProvided(true);
    (!form.email.value)? setIsEmailProvided(false): setIsEmailProvided(true);
    //(!form.password.value)? setIsPasswordProvided(false): setIsPasswordProvided(true);
    //(!form.confirmPassword.value)? setIsConfirmPasswordProvided(false): setIsConfirmPasswordProvided(true);
    (form.password.value !== form.confirmPassword.value)? setIsPasswordMatch(false): setIsPasswordMatch(true);
    (subSelected === "")? setIsSubProvided(false): setIsSubProvided(true);

    return (
      isUsernameProvided &&
      //isPasswordProvided &&
      isEmailProvided &&
      isPasswordMatch &&
      isSubProvided
    );
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;

    if(validateForm(form)) {

      const fd = {
        username: form.username.value,
        email: form.email.value,
        subId: subSelected
      };

      if(isPasswordProvided) fd.password = form.password.value;

      await putApi(`/user/${userId}`, fd).then((r) => {
        //Need to add some kind of notification
        toast.success("Account updated successfully!");
      }).catch((e) => {
        if(e.message === '409') setIsUniqueUsername(false);
      })
    }
  };

  return (
    <form onSubmit={handleSubmit} name="updateAccount"  class="listViewContainLarge">
      <h3>Account</h3>
      <ToastContainer />
      <div className="form-group">
        <label>username</label>
        <input name="username" type="text" className="form-control" defaultValue={userData.username} placeholder="Enter username" />
        <div className="form-validation-error">{ isUsernameProvided? "": "Please provide a username" }</div>
        <div className="form-validation-error">{ isUniqueUsername? "": "Username already exists" }</div>
      </div>

      <div className="form-group">
        <label>Email</label>
        <input name="email" type="email" className="form-control"  defaultValue={userData.email} placeholder="Enter email" />
        <div className="form-validation-error">{ isEmailProvided? "": "Please provide an email" }</div>
      </div>

      <div className="form-group">
        <label>Password</label>
        <input name="password" type="password" className="form-control" placeholder="Enter password" />
        <div className="form-validation-error">{ isPasswordProvided? "": "Please provide a password" }</div>
      </div>

      <div className="form-group">
        <label>Confirm  Password</label>
        <input name="confirmPassword" type="password" className="form-control" placeholder="Re-Enter password" />
        <div className="form-validation-error">{ isConfirmPasswordProvided? "": "Please re-enter password" }</div>
        <div className="form-validation-error">{ isPasswordMatch? "": "Password does not match" }</div>
      </div>


      { isSubsAvailable && isSubProvided && (
        <div className="form-group">
        <label>Choose a subscription</label>
        { subsData.map((sub) =>
          <div><label><input type="radio" value={sub._id} name="subId" checked={iAmTheSub(sub._id)()} onChange={subWasSelected}/> {sub.isPriceTBD? "$TBD": formatter.format(sub.price)} {sub.name}</label></div>
        )}
        <div className="form-validation-error">{ isSubProvided? "": "Please select a subscription" }</div>
        </div>
      )}

      <button type="submit" className="btn btn-dark btn-lg btn-block">Update</button>

    </form>
  );
}
