import React from "react";
import { getApi, deleteApi } from '../api/api';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

export default (props) => {

  const history = useHistory();

  const [subscriptionList, setSubscriptionList] = useState([]);

  const updateList = () => {
    getApi('/subscriptions').then((t) => {
      setSubscriptionList(t);
    }).catch((e) => console.log("Could not get subscriptions with error: ", e));
  }

  if(subscriptionList.length === 0) {
    updateList();
  }

  const createSubscription = () => {
    history.push('/subscription');
  }

  const updateSubscription = (subscriptionId) => {
    history.push(`/subscription/${subscriptionId}`);
  }

  const deleteSubscription = async (subscriptionId) => {
    await deleteApi(`/subscription/${subscriptionId}`).then((t) => {
      console.log("Subscription was deleted: ", t);
    }).catch((e) => {
      console.log("Could not delete Subscription: ", e);
    });
    updateList();
  }

  return (
    <div class="listViewContain">
      <h2>Subscriptions &nbsp;
        <button type="button" className="btn btn-outline-success" onClick={createSubscription}>+</button>
      </h2>
      <div className="SimpleList">
        <ul>
          {subscriptionList.map(function(subscription){
            return (
              <li key={ subscription._id }>
                <img alt="Subscription Icon" src="/money.png" />
                <h3>{subscription.name}</h3>
                <div>{subscription.desc}</div>
                <div className="btn-group mr-2 SimpleGroup" role="group" aria-label="CRUD">
                  <button type="button" className="btn btn-outline-success btn-sm" onClick={ () => updateSubscription(subscription._id) }>✎</button> 
                  <button type="button" className="btn btn-outline-danger btn-sm" onClick={ () => deleteSubscription(subscription._id) }>🗑</button>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
