import React from "react";
import { useHistory, Link } from 'react-router-dom';
import { postApi, getApi, putApi } from '../api/api';
import { useState } from 'react';

export default (props) => {
  console.log(props);
  const history = useHistory();

  const [isUpdate, serviceKeyId] = (() => {
    const p = props.location.pathname.split("/");
    const isUp = (p.length > 2)? true: false;
    const tId = (isUp)? p.pop(): null;
    return [isUp, tId];
  })();

  const [ isDataFetched, setIsDataFetched ] = useState(false);
  const [ serviceKeyData, setServiceKeyData ] = useState({});
  const [ isAccountProvided, setIsAccountProvided ] = useState(true);
  const [ isApiKeyProvided, setIsApiKeyProvided ] = useState(true);
  const [ isTPSProvided, setIsTPSProvided ] = useState(true);

  if(isUpdate && !isDataFetched) {
    getApi(`/serviceKey/${serviceKeyId}`).then((t) => {
      t.tps = t.tps? t.tps: 4;
      setIsDataFetched(true);
      setServiceKeyData(t);
    }).catch((e) => {
      console.log("Could not fetch serviceKey data: ", e);
      history.push("/serviceKeys");
    });
  }

  const validateForm = (form) => {
    (!form.account.value)? setIsAccountProvided(false): setIsAccountProvided(true);
    (!form.apiKey.value)? setIsApiKeyProvided(false): setIsApiKeyProvided(true);
    (isNaN(form.tps.value))? setIsTPSProvided(false): setIsTPSProvided(true);

    return form.account.value && form.apiKey.value && !isNaN(form.tps.value)
  }


  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    if(validateForm(form)) {
      const fd = {
        account: form.account.value,
        apiKey: form.apiKey.value,
        tps: parseInt(form.tps.value)
      };

      if(isUpdate) {
        await putApi(`/serviceKey/${serviceKeyId}`, fd).then((r) => {
          history.push('/serviceKeys');
        }).catch((e) => {
          console.log("PUT /serviceKey: Error: ", e);
        })
      } else {
        await postApi('/serviceKey', fd).then((r) => {
          history.push('/serviceKeys');
        }).catch((e) => {
          console.log("POST /serviceKey: Error: ", e);
        });
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <h3>Create A Service Key</h3>

      <div className="form-group">
        <label>Account Name</label>
        <input name="account" type="text" className="form-control" defaultValue={serviceKeyData.account} placeholder="Enter ServiceKey Account Name" />
        <div className="form-validation-error">{ isAccountProvided? "": "Please provide a ServiceKey Account Name" }</div>
      </div>

      <div className="form-group">
        <label>API Key</label>
        <input name="apiKey" type="text" className="form-control" defaultValue={serviceKeyData.apiKey} placeholder="Enter An API Key" />
        <div className="form-validation-error">{ isApiKeyProvided? "": "Please provide an API Key" }</div>
      </div>

      <div className="form-group">
        <label>Transactions / Second</label>
        <input name="tps" type="text" className="form-control" defaultValue={serviceKeyData.tps} placeholder="Enter the TPS (Number)" />
        <div className="form-validation-error">{ isTPSProvided? "": "Please provide a valid TPS number" }</div>
      </div>


      <button type="submit" className="btn btn-dark btn-lg btn-block">{ isDataFetched? 'Update': 'Create' }</button>

      <p className="forgot-password text-right">
        <Link to={"/serviceKeys"}>&lt; Back</Link>
      </p>
    </form>
  );
}
